<template>
    <div>
        <b-overlay
            :show="isLoading"
            rounded
            opacity="0.6"
            spinner-variant="primary"
            spinner-mediam
        >
            <transition
                :name="$store.state.appConfig.layout.routerTransition"
                mode="out-in"
            >
                <b-row>
                    <b-col md="9">
                        <b-card no-body>
                            <b-card-body class="">
                                <p>يشجع هذا العرض عملاءك للحصول على خصم كبير على منتج مختار، مما يساهم في زيادة المبيعات ورفع متوسط الفاتورة يظهر هذا العرض لمدة 60 ثانية فقط في صفحة الشراء ثم يختفي.</p>
                                <div class="text-center">
                                    <img src="https://cdn.mhd.sa/asset/images/quick-deal.png" width="50%" />
                                </div>
                                <b-form @submit.prevent="update">

                                    <!-- Product -->
                                    <b-col cols="12">
                                        <b-form-group
                                            :label="$t('The Product')"
                                            label-for="v-product"
                                        >
                                            <v-select
                                                v-model="quick_deal.product"
                                                :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                                                label="name"
                                                :options="products"
                                            >
                                                <template #option="{ name, largeThumbnail }">
                                                    <b-img :src="largeThumbnail" width="50" height="50" />
                                                    <span> {{ name }}</span>
                                                </template>
                                            </v-select>
                                        </b-form-group>
                                    </b-col>

                                    <b-col cols="12">
                                        <b-form-group
                                            :label="$t('Discount Percentage')"
                                            label-for="v-is-active"
                                        >
                                            <b-input-group>
                                                <b-input-group-prepend is-text>
                                                    <feather-icon icon="PercentIcon" />
                                                </b-input-group-prepend>
                                                <b-form-input placeholder="20" v-model="quick_deal.discount" />
                                            </b-input-group>
                                        </b-form-group>
                                    </b-col>

                                    <!-- Is Active -->
                                    <b-col cols="12">
                                        <b-form-group
                                        :label="$t('Is Active ?')"
                                        label-for="v-is-active"
                                        >
                                            <b-form-checkbox
                                                id="v-is-active"
                                                class="custom-control-primary"
                                                v-model="quick_deal.is_active"
                                                name="is_active"
                                                switch
                                            >
                                                <span class="switch-icon-left">
                                                    <feather-icon icon="CheckIcon" />
                                                </span>
                                                <span class="switch-icon-right">
                                                    <feather-icon icon="XIcon" />
                                                </span>
                                            </b-form-checkbox>
                                        </b-form-group>
                                    </b-col>

                                    <!-- submit -->
                                    <b-col cols="12">
                                        <b-button
                                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                        type="submit"
                                        variant="primary"
                                        class="mr-1 mt-1"
                                        >
                                            <template v-if="!isLoadingSave">
                                                {{$t('Save')}}
                                            </template>
                                            <template v-if="isLoadingSave">
                                                {{$t('Saveing')}}...
                                                <b-spinner small />
                                            </template>
                                        </b-button>
                                    </b-col>
                                </b-form>
                            </b-card-body>
                        </b-card>
                    </b-col>
                </b-row>
            </transition>
        </b-overlay>
    </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/useJwt'
export default {
    directives: {
        Ripple,
    },
    mounted() {
        this.getProducts().then(() => {
            this.getResults()
        });
    },
    data() {
        return {
            quick_deal: {
                product: {}
            },
            isLoading: true,
            isLoadingSave: false,
            products: []
        }
    },
    methods: {
        getResults() {
            useJwt.post('/store/config/get-by-id',{key: 'quick_deal'})
            .then((response) => {
                // console.log(response.data)
                if (response.data.data && Object.keys(response.data.data).length) {
                    this.quick_deal = response.data.data.quick_deal
                    this.quick_deal.is_active =  this.quick_deal.is_active ? true : false
                    this.quick_deal.product = this.products.find(product => { return product.id == this.quick_deal.product_id })
                }
                this.isLoading = false
            })
            .catch(response => {
                console.log(response);
            });
        },
        update() {
            this.isLoadingSave = true;
            this.quick_deal.product_id = this.quick_deal.product.id
            
            useJwt.post('/marketing-offers/quick-deal/save',this.quick_deal)
            .then((response) => {
                // console.log(response.data);
                this.isLoadingSave = false
                this.$root.showToast('success', 'save', '')
                // this.quick_deal = response.data.data;
            })
            .catch(error => {
                // console.log(response);
                this.isLoadingSave = false
                this.$root.showAlert('error', error.response.data.message)
            });
        },
        async getProducts(){
            await useJwt.get('/products/get-all')
            .then((response) => {
                // console.log(response.data)
                this.products = response.data.data
            })
            .catch(response => {
                // console.log(response);
            });
        },
    },
}
</script>

<style lang="sass" scoped>

</style>
